import { faFileContract } from '@fortawesome/free-solid-svg-icons';
import { Check } from '@mui/icons-material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import mainImage from '../../assets/images/virtual-office/main.jpg';
import { brasilWhatsapp, openWhatsappChat } from '../../utils/whatsapp';
import FontAwesomeSvgIcon from '../FontAwesomeSvgIcon/index';

function createData(
  feature: string,
  basic: boolean | 'string',
  medium: boolean | 'string',
  advanced: boolean | 'string'
) {
  return { feature, basic, medium, advanced };
}

const rows = [
  createData(
    'Registro do endereço comercial (Google Maps, Instagram, cartão de visita)',
    true,
    true,
    true
  ),
  createData('Recebimento de correspondência', true, true, true),
  createData('Habite-se comercial e alvará', true, true, true),
  createData(
    'Registro do endereço fiscal (abertura de CNPJ, fins tributários, etc)',
    false,
    true,
    true
  ),
  createData('50% de desconto em horas excedentes', false, true, true),
  createData('Horas em sala de reunião', '1', '2', '8'),
];

function formatValue(value: any) {
  return (
    (typeof value === 'boolean' && value && <Check />) || (
      <Typography variant='h6'>{value}</Typography>
    )
  );
}

function VirtualOfficeContent() {
  return (
    <>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ mt: 20, pt: 8, pb: 6, backgroundColor: '#3E3D38' }}
      >
        <Typography
          variant='h2'
          align='center'
          color='background.default'
          gutterBottom
          sx={{
            fontFamily: 'Gabriela Stencil Black',
            fontWeight: 'fontWeightBold',
          }}
        >
          Escritório Virtual
        </Typography>
      </Container>
      <Container maxWidth='lg' sx={{ pt: 15 }}>
        <Card>
          <CardMedia component='img' height='300' image={mainImage} alt='virtual office' />
          <CardContent>
            <TableContainer>
              <Table
                sx={{
                  minWidth: 650,
                  '& .MuiTableCell-root': {
                    border: '1px solid rgb(223, 225, 230)',
                  },
                }}
                aria-label='virtual office pricing'
              >
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align='center' sx={{ border: '1px solid rgb(223, 225, 230)' }}>
                      <AlternateEmailIcon />
                      <Typography>Endereço Comercial</Typography>
                      <Button
                        variant='contained'
                        color='info'
                        sx={{ mt: 2 }}
                        onClick={() =>
                          openWhatsappChat(
                            brasilWhatsapp,
                            'Olá! Gostaria de saber mais sobre endereço comercial'
                          )
                        }
                      >
                        Saiba mais
                      </Button>
                    </TableCell>
                    <TableCell align='center' sx={{ border: '1px solid rgb(223, 225, 230)' }}>
                      <FontAwesomeSvgIcon icon={faFileContract} />
                      <Typography>Endereço Fiscal</Typography>
                      <Button
                        variant='contained'
                        color='info'
                        sx={{ mt: 2 }}
                        onClick={() =>
                          openWhatsappChat(
                            brasilWhatsapp,
                            'Olá! Gostaria de saber mais sobre endereço fiscal'
                          )
                        }
                      >
                        Saiba mais
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      component='th'
                      scope='row'
                      align='center'
                      colSpan={6}
                      sx={{
                        backgroundColor: '#ebebeb',
                      }}
                    >
                      <Typography variant='h6'>Benefícios</Typography>
                    </TableCell>
                  </TableRow>
                  {rows.map((row) => (
                    <TableRow key={row.feature} hover>
                      <TableCell component='th' scope='row'>
                        <Typography
                          color='text.secondary'
                          variant='h6'
                          fontWeight='fontWeightMedium'
                        >
                          {row.feature}
                        </Typography>
                      </TableCell>
                      <TableCell align='center' sx={{ border: '1px solid rgb(223, 225, 230)' }}>
                        {formatValue(row.basic)}
                      </TableCell>
                      <TableCell align='center' sx={{ border: '1px solid rgb(223, 225, 230)' }}>
                        {formatValue(row.medium)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}

export default function VirtualOffice() {
  return <VirtualOfficeContent />;
}
