import { useState } from 'react';

import { Alert, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { sendContactEmail } from '../../utils/email';

const validationSchema = yup.object({
  name: yup.string().required('Informe seu nome'),
  email: yup.string().email('Informe um email válido').required('Informe seu email'),
  phone: yup.string().min(10, 'Informe um telefone válido').required('Informe seu telefone'),
  unit: yup
    .string()
    .oneOf(['Bairro Brasil', 'Bairro Santa Mônica', 'Ambos'], 'Selecione uma unidade')
    .required('Selecione uma unidade'),
  message: yup.string().min(1).required('Digite sua mensagem'),
});

function ContactForm() {
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorOpen(false);
    setSuccessOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      name: undefined,
      email: undefined,
      phone: undefined,
      unit: 'Ambos',
      message: undefined,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await sendContactEmail(values);
        setSuccessOpen(true);
      } catch (err) {
        setErrorOpen(true);
      }
    },
  });

  return (
    <>
      <Container id='Contato' disableGutters maxWidth='sm' sx={{ mt: 20 }}>
        <Typography
          component='h1'
          variant='h3'
          align='center'
          color='text.primary'
          gutterBottom
          sx={{
            fontFamily: 'Gabriela Stencil Black',
          }}
        >
          Vamos trabalhar juntos!
        </Typography>
        <Typography variant='h6' align='center' color='text.secondary' component='p'>
          Entraremos em contato o mais rápido possível
        </Typography>
      </Container>
      <Container
        sx={{
          width: 700,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id='name'
            name='name'
            label='Nome'
            value={formik.values.name}
            placeholder='Seu nome'
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant='standard'
          />
          <TextField
            fullWidth
            id='email'
            name='email'
            label='E-mail'
            value={formik.values.email}
            placeholder='seuemail@seuemail.com'
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant='standard'
          />
          <TextField
            fullWidth
            id='phone'
            name='phone'
            label='Telefone'
            value={formik.values.phone}
            placeholder='(XX) XXXXX-XXXX'
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            variant='standard'
          />
          <InputLabel id='unit-label' sx={{ mt: 2 }}>
            Unidade
          </InputLabel>
          <Select
            fullWidth
            id='unit'
            labelId='unit-label'
            name='unit'
            label='Unidade'
            defaultValue='Ambos'
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.unit && Boolean(formik.errors.unit)}
          >
            <MenuItem value='Bairro Brasil'>Bairro Brasil</MenuItem>
            <MenuItem value='Bairro Santa Mônica'>Bairro Santa Mônica</MenuItem>
            <MenuItem value='Ambos'>Ambos</MenuItem>
          </Select>
          <TextField
            fullWidth
            id='message'
            name='message'
            label='Mensagem'
            value={formik.values.message}
            placeholder='Desejo fazer um orçamento.'
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            variant='standard'
            multiline
            rows={4}
          />
          <Button color='primary' variant='contained' fullWidth type='submit' sx={{ mt: 3 }}>
            Enviar
          </Button>
        </form>
      </Container>
      <Snackbar
        open={errorOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          severity='error'
          sx={{
            color: '#ffffff',
            backgroundColor: '#d32f2f',
          }}
          variant='filled'
        >
          Houve um problema para enviar sua mensagem
        </Alert>
      </Snackbar>
      <Snackbar
        open={successOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          severity='success'
          sx={{
            color: '#ffffff',
            backgroundColor: '#2e7d32',
          }}
          variant='filled'
        >
          Mensagem enviada, em breve entraremos em contato
        </Alert>
      </Snackbar>
    </>
  );
}

export default ContactForm;
