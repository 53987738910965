import AirIcon from '@mui/icons-material/Air';
import BoltIcon from '@mui/icons-material/Bolt';
import ChairIcon from '@mui/icons-material/Chair';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import FeedIcon from '@mui/icons-material/Feed';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import HomeIcon from '@mui/icons-material/Home';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonIcon from '@mui/icons-material/Person';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PrintIcon from '@mui/icons-material/Print';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';

const benefits = [
  { icon: <BoltIcon />, text: 'Internet de Alta Performance' },
  { icon: <LocalCafeIcon />, text: 'Café à vontade' },
  { icon: <FoodBankIcon />, text: 'Cozinha compartilhada' },
  { icon: <AirIcon />, text: 'Ambientes climatizados' },
  { icon: <PersonIcon />, text: 'Recepção' },
  { icon: <PrintIcon />, text: 'Impressora' },
  { icon: <HomeIcon />, text: 'Área externa' },
  { icon: <MailOutlineIcon />, text: 'Gestão de correspondência' },
  { icon: <ChatBubbleOutlineIcon />, text: 'Rodada de networking mensal' },
  { icon: <ChairIcon />, text: 'Ambientes criativos' },
  { icon: <FeedIcon />, text: 'Contrato com todas as despesas inclusas' },
  { icon: <PinDropIcon />, text: 'Duas unidades para melhor lhe atender' },
];

function BenefitsContent() {
  return (
    <Container id='Benefícios' disableGutters maxWidth={false} sx={{ pt: 10 }}>
      <Typography
        variant='h1'
        align='center'
        color='#3E3D38'
        gutterBottom
        sx={{
          pb: 8,
          fontFamily: 'Gabriela Stencil Black',
          fontWeight: 'fontWeightBold',
        }}
      >
        Benefícios
      </Typography>
      <Container>
        <Grid container spacing={2}>
          {benefits.map((b) => (
            <Grid item key={b.text} xs={12} sm={12} md={6} sx={{ m: 0 }}>
              <Container
                disableGutters
                maxWidth={false}
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  lineHeight: 1,
                }}
              >
                <Box
                  sx={{
                    m: 0,
                    p: 0,
                    '& svg': {
                      fontSize: 40,
                    },
                    mr: 3,
                  }}
                >
                  {b.icon}
                </Box>
                <Typography variant='h5'>{b.text}</Typography>
              </Container>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Container>
  );
}

export default function Benefits() {
  return <BenefitsContent />;
}
