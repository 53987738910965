import { Box, Card, CardContent, Typography } from '@mui/material';
import Container from '@mui/material/Container';

import backgroundImage from '../../assets/images/about-us/background.jpg';
import logoAlignedLeft from '../../assets/images/logo-aligned-left.png';

function AboutUs() {
  return (
    <Container maxWidth={false} disableGutters>
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '784px',
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        <Card
          sx={{
            width: 450,
            ml: 20,
            p: 2,
            background: 'rgba(255,255,255,0.8)',
            backdropFilter: 'blur(20px)',
            borderRadius: 10,
            boxShadow: 3,
          }}
        >
          <CardContent sx={{ textAlign: 'left' }}>
            <Box
              component='img'
              sx={{
                width: 300,
                mb: 5,
                userSelect: 'none',
              }}
              alt='Logo'
              src={logoAlignedLeft}
            />
            <Typography fontWeight='fontWeightBold' fontSize={50} sx={{ color: '#2f3031' }}>
              Um local,
            </Typography>
            <Typography
              fontWeight='fontWeightBold'
              fontSize={50}
              sx={{ fontFamily: 'Gabriela Stencil Black', color: '#2f3031' }}
            >
              inúmeras possibilidades
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}

export default AboutUs;
