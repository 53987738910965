import { Email, Facebook, Instagram, Phone, Place, WhatsApp } from '@mui/icons-material';
import { Box, Button, Divider, Link, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';

import linktreeLogo from '../../assets/images/footer/linktree-logo.png';
import logoSm from '../../assets/images/logo-sm.png';
import { brasilWhatsapp, santaMonicaWhatsapp } from '../../utils/whatsapp';

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

function openInNewTab(url) {
  window.open(url, '_blank').focus();
}

function Copyright(props: any) {
  return (
    <Typography variant='body2' color='text.secondary' align='center' {...props}>
      {new Date().getFullYear()} Vila Marieta - Todos os direitos reservados
    </Typography>
  );
}

function FooterContent() {
  return (
    <Container
      maxWidth='md'
      component='footer'
      sx={{
        mt: 5,
        py: [3, 3],
      }}
    >
      <Grid container spacing={4} justifyContent='space-between'>
        <Grid item xs={6} sm={3} key='footer-menu'>
          <Typography variant='h6' color='text.primary' gutterBottom>
            Menu
          </Typography>
          <ul>
            <li key='workstations'>
              <StyledLink href='#virtual-office' variant='subtitle1' color='text.secondary'>
                Estações de trabalho
              </StyledLink>
            </li>
            <li key='virtual-office'>
              <StyledLink href='#virtual-office' variant='subtitle1' color='text.secondary'>
                Escritório virtual
              </StyledLink>
            </li>
            <li key='private-rooms'>
              <StyledLink href='#private-rooms' variant='subtitle1' color='text.secondary'>
                Salas privativas
              </StyledLink>
            </li>
          </ul>
          <Button
            variant='contained'
            sx={{ mt: 2 }}
            href={`https://api.whatsapp.com/send?phone=55${brasilWhatsapp}`}
            rel='noopener noreferrer'
            target='_blank'
          >
            Fale conosco
          </Button>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginTop: 8,
            }}
          >
            <Email />
            <Typography variant='h6' color='text.secondary' align='center' sx={{ ml: 1 }}>
              E-mail
            </Typography>
          </div>
          <Typography>
            <Link
              rel='noopener noreferrer'
              target='_blank'
              href='mailto:contato@vilamarieta.com'
              variant='subtitle1'
              color='text.secondary'
            >
              contato@vilamarieta.com
            </Link>
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginTop: 8,
            }}
          >
            <Phone />
            <Typography variant='h6' color='text.secondary' align='center'>
              Telefone
            </Typography>
            <Typography>
              <Link
                rel='noopener noreferrer'
                target='_blank'
                href='tel:3432325848'
                variant='subtitle1'
                color='text.secondary'
              >
                (34) 3232-5848
              </Link>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} sm={3} key='footer-address-1'>
          <Typography
            variant='subtitle1'
            color='text.primary'
            sx={{ fontWeight: 600 }}
            gutterBottom
          >
            Unidade Bairro Brasil
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Place />
            <Typography variant='h6' color='text.secondary' align='center'>
              Endereço
            </Typography>
          </div>
          <ul style={{ color: '#6a686a' }}>
            <li>Rua Bahia, 1220</li>
            <li>Bairro Brasil</li>
            <li>Uberlândia - MG</li>
            <li>38400-662</li>
            <li>&nbsp;</li>
          </ul>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <WhatsApp />
            <Typography variant='h6' color='text.secondary' align='center'>
              WhatsApp
            </Typography>
            <Typography>
              <Link
                rel='noopener noreferrer'
                target='_blank'
                href={`https://api.whatsapp.com/send?phone=55${brasilWhatsapp}`}
                variant='subtitle1'
                color='text.secondary'
              >
                (34) 99172-7563
              </Link>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} sm={3} key='footer-address-2'>
          <Typography
            variant='subtitle1'
            color='text.primary'
            gutterBottom
            sx={{ fontWeight: 600 }}
          >
            Unidade Santa Mônica
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Place />
            <Typography variant='h6' color='text.secondary' align='center'>
              Endereço
            </Typography>
          </div>
          <ul style={{ color: '#6a686a' }}>
            <li>Rua Nordal Gonçalves de Melo, 1128</li>
            <li>Bairro Santa Mônica</li>
            <li>Uberlândia - MG</li>
            <li>38408-218</li>
          </ul>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <WhatsApp />
            <Typography variant='h6' color='text.secondary' align='center'>
              WhatsApp
            </Typography>
            <Typography>
              <Link
                rel='noopener noreferrer'
                target='_blank'
                href={`https://api.whatsapp.com/send?phone=55${santaMonicaWhatsapp}`}
                variant='subtitle1'
                color='text.secondary'
              >
                (34) 99336-0176
              </Link>
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          key='footer-phones'
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
        >
          <Box
            component='img'
            sx={{
              width: 200,
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
            }}
            alt='Logo'
            src={logoSm}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Box
              onClick={() => openInNewTab('https://www.instagram.com/vilamarietacoworking/')}
              sx={{ cursor: 'pointer' }}
            >
              <Instagram />
            </Box>
            <Box
              onClick={() => openInNewTab('https://facebook.com/vilamarietacoworking')}
              sx={{ cursor: 'pointer' }}
            >
              <Facebook />
            </Box>
            <Box
              component='img'
              src={linktreeLogo}
              alt='Linktree logo'
              onClick={() => openInNewTab('https://linktr.ee/vilamarietacoworking')}
              sx={{
                display: 'block',
                height: '61%',
                cursor: 'pointer',
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Divider variant='middle' sx={{ mt: 3 }} />
      <Copyright sx={{ mt: 3 }} />
    </Container>
  );
}

export default function Footer() {
  return <FooterContent />;
}
