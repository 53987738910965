import { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';

import { Close, WhatsApp } from '@mui/icons-material';
import { IconButton, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { brasilWhatsapp, openWhatsappChat } from '../../utils/whatsapp';
import * as images from './images';

const tiers = [
  {
    title: 'Sala Crie',
    price: '20,50',
    description: ['Sala de treinamento para até 12 pessoas', 'Datashow', 'Flipchart'],
    buttonText: 'Saiba mais',
    buttonVariant: 'contained',
    photos: [images.crie1, images.crie2, images.crie3],
  },
  {
    title: "Sala C'alma",
    price: '20,50',
    description: [
      'Sala rotativa para até 3 pessoas',
      'Mesa c/ cadeiras e poltronas',
      'Ideal para vídeos e fotos',
    ],
    buttonText: 'Saiba mais',
    buttonVariant: 'contained',
    photos: [images.calma1, images.calma2, images.calma3],
  },
  {
    title: 'Sala Reunir',
    price: '20,50',
    description: [
      'Sala rotativa para até 4 pessoas',
      'TV c/ entrada HDMI',
      'Ponto central de tomada',
    ],
    buttonText: 'Saiba mais',
    buttonVariant: 'contained',
    photos: [images.reunir1, images.reunir2, images.reunir3],
  },
];

const secondTiers = [
  {
    title: 'Sala Energia',
    price: '250',
    description: [
      'Contrato semestral ou anual',
      'Sala privativa para até 3 pessoas',
      'Bancadas fixas',
      'Prateleiras',
    ],
    buttonText: 'Saiba mais',
    buttonVariant: 'contained',
    photos: [images.energia1, images.energia2, images.energia3, images.energia4, images.energia5],
  },
  {
    title: 'Sala Sucesso',
    price: '250',
    description: [
      'Contrato semestral ou anual',
      'Sala privativa individual',
      'Poltronas',
      'Armários',
    ],
    buttonText: 'Saiba mais',
    buttonVariant: 'contained',
    photos: [images.sucesso1, images.sucesso2, images.sucesso3, images.sucesso4],
  },
  {
    title: 'Sala Dividir',
    price: '320',
    description: [
      'Contrato semestral ou anual',
      'Sala privativa para até 4 pessoas',
      'Armários',
      'Vidros laterais',
    ],
    buttonText: 'Saiba mais',
    buttonVariant: 'contained',
    photos: [
      images.dividir1,
      images.dividir2,
      images.dividir3,
      images.dividir4,
      images.dividir5,
      images.dividir6,
    ],
  },
];

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '90%',
  width: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const carouselSettings = {
  autoPlay: true,
  animation: 'fade',
  indicators: true,
  duration: 500,
  navButtonsAlwaysVisible: true,
  navButtonsAlwaysInvisible: false,
  cycleNavigation: true,
  fullHeightHover: true,
  swipe: true,
};

function PrivateRoomsContent() {
  const [open, setOpen] = useState(false);
  const [selectedRoomTitle, setSelectedRoomTitle] = useState(undefined);
  const [selectedRoom, setSelectedRoom] = useState(undefined);
  const handleOpen = (roomTitle) => {
    setSelectedRoomTitle(roomTitle);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    let room = tiers.find((r) => r.title === selectedRoomTitle);
    if (room === undefined) {
      room = secondTiers.find((r) => r.title === selectedRoomTitle);
    }
    setSelectedRoom(room);
  }, [selectedRoomTitle]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyle}>
          <Typography id='modal-modal-title' component='h1' variant='h3'>
            {selectedRoom?.title}
          </Typography>
          <IconButton onClick={handleClose} sx={{ float: 'right', top: '-10%', right: '-1%' }}>
            <Close />
          </IconButton>
          <Grid container>
            <Grid item xs={9}>
              <Carousel height='60vh' {...carouselSettings}>
                {selectedRoom?.photos.map((photo) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'black',
                    }}
                  >
                    <div style={{ height: '60vh' }}>
                      <img
                        alt={`${photo}`}
                        src={photo}
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                    </div>
                  </div>
                ))}
              </Carousel>
            </Grid>
            <Grid item xs={3}>
              <Box
                disableGutters
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignContent: 'space-between',
                }}
              >
                <Box>
                  <Typography component='h2' variant='h4' sx={{ mb: 3 }}>
                    Sobre a sala
                  </Typography>
                  <ul>
                    {selectedRoom?.description.map((line) => (
                      <Typography
                        component='li'
                        variant='h6'
                        align='center'
                        key={line}
                        sx={{ color: '#555' }}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </Box>
                <Box
                  sx={{
                    backgroundColor: '#1ed45f',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    width: '12rem',
                    height: '3rem',
                    borderRadius: '3px',
                    mb: 5,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    openWhatsappChat(
                      brasilWhatsapp,
                      `Olá! Gostaria de saber mais sobre a ${selectedRoom?.title}`
                    )
                  }
                >
                  <WhatsApp />
                  <Typography>Quero saber mais</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ mt: 20, pt: 8, pb: 6, backgroundColor: '#92A069' }}
      >
        <Typography
          variant='h2'
          align='center'
          color='background.default'
          gutterBottom
          sx={{
            fontFamily: 'Gabriela Stencil Black',
            fontWeight: 'fontWeightBold',
          }}
        >
          Salas Privativas
        </Typography>
      </Container>
      <Container sx={{ pt: 15 }}>
        <Grid container spacing={5} alignItems='flex-end'>
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={12} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography fontSize={12} color='text.secondary'>
                      A partir de&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography component='h3' variant='h4' color='text.primary'>
                      R$ {tier.price}
                    </Typography>
                    <Typography variant='h7' color='text.secondary'>
                      /hora
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component='li' variant='subtitle1' align='center' key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => handleOpen(tier.title)}
                    fullWidth
                    variant={tier.buttonVariant as 'outlined' | 'contained'}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={5} alignItems='flex-end' mt={2}>
          {secondTiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={12} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography variant='h6' color='text.secondary'>
                      Consulte disponibilidade
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component='li' variant='subtitle1' align='center' key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => handleOpen(tier.title)}
                    fullWidth
                    variant={tier.buttonVariant as 'outlined' | 'contained'}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default function PrivateRooms() {
  return <PrivateRoomsContent />;
}
