import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import './styles.css';

import testimonialsFile from '../../assets/data/testimonials.json';
import { isCellphone } from '../../utils/device';
import theme from '../Theme';

function importAll(r: __WebpackModuleApi.RequireContext) {
  const images = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

const partnersLogos = importAll(
  require.context('../../assets/images/partners-logos', false, /\.(png|jpe?g|svg)$/)
);

const partnersAvatars = importAll(
  require.context('../../assets/images/partners-avatars', false, /\.(png|jpe?g|svg)$/)
);

type TestimonialProps = {
  authorAvatar: string;
  author: string;
  text: string;
  companyLogo: string;
  color: string;
};

const testimonials = [];

testimonialsFile.testimonials.forEach((item) => {
  testimonials.push({
    ...item,
    companyLogo: partnersLogos[item.companyLogo],
    authorAvatar: partnersAvatars[item.authorAvatar],
  });
});

function Arrow(props: { left?: boolean; onClick: (e: any) => void }) {
  return (
    <Box
      sx={{
        width: 40,
        height: 40,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        left: props.left ? 5 : 'auto',
        right: props.left ? 'auto' : 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: '0 2',
        visibility: 'visible',
        transition: 'none 280ms ease-out 0s',
        bgcolor: 'white',
        borderRadius: 10,
        border: 0.5,
        borderColor: '#ccc',
      }}
    >
      {props.left ? (
        <IconButton onClick={props.onClick}>
          <ArrowBack sx={{ color: 'black' }} />
        </IconButton>
      ) : (
        <IconButton onClick={props.onClick}>
          <ArrowForward sx={{ color: 'black' }} />
        </IconButton>
      )}
    </Box>
  );
}

export function Testimonial({ authorAvatar, author, text, companyLogo, color }: TestimonialProps) {
  return (
    <Container
      className='keen-slider__slide'
      sx={{
        pt: 5,
        pb: 3,
        bgcolor: color,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
        '&:hover': {
          boxShadow: theme.shadows[6],
        },
        transition: 'box-shadow 150ms ease-out 0s',
      }}
    >
      <Avatar src={authorAvatar} alt='author-avatar' sx={{ width: 120, height: 120 }} />
      <Container sx={{ mt: 4 }}>
        <Typography variant='subtitle1' fontWeight='500'>
          {text}
        </Typography>
      </Container>
      <Container
        sx={{
          mt: 3,
          mb: 3,
          display: 'flex',
          justifyContent: 'right',
          fontStyle: 'italic',
        }}
      >
        <Typography variant='subtitle1' fontWeight='800'>
          {author}
        </Typography>
      </Container>
      <img
        src={companyLogo}
        alt='company-logo'
        style={{
          maxWidth: 140,
          maxHeight: 130,
          width: 'auto',
          height: 'auto',
          display: 'block',
        }}
      />
    </Container>
  );
}

const keenSliderStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '10 0',
};

export default function TestimonialSlider() {
  const [sliderRef, instanceRef] = useKeenSlider<Testimonial>({
    loop: true,
    mode: 'free',
    slides: {
      perView: isCellphone() ? 1.5 : 3.5,
      spacing: 80,
    },
    dragSpeed: 0.3,
  });
  return (
    <Container maxWidth={false} disableGutters sx={{ mt: 20, position: 'relative' }}>
      <div ref={sliderRef} className='keen-slider' style={keenSliderStyle}>
        {testimonials.map((item) => (
          <Testimonial {...item} key={item.author} />
        ))}
      </div>
      <Arrow left onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()} />
      <Arrow onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()} />
    </Container>
  );
}
