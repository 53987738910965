import { Box, Button } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { brasilWhatsapp, openWhatsappChat } from '../../utils/whatsapp';

function PlansContent() {
  return (
    <Container id='Planos' disableGutters maxWidth={false} sx={{ pt: 10 }}>
      <Typography
        variant='h1'
        align='center'
        color='#3E3D38'
        gutterBottom
        sx={{
          pb: 8,
          fontFamily: 'Gabriela Stencil Black',
          fontWeight: 'fontWeightBold',
        }}
      >
        Planos
      </Typography>
      <Container>
        <Grid container spacing={10}>
          <Grid item xs={4}>
            <Box>
              <Container
                disableGutters
                maxWidth={false}
                sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}
              >
                <Typography
                  align='center'
                  sx={{
                    fontFamily: 'Gabriela Stencil Black',
                    fontSize: 29,
                    color: '#7B5248',
                  }}
                >
                  Estações de Trabalho
                </Typography>
              </Container>
              <Typography fontSize={25} align='center' sx={{ mt: 2, mb: 2 }}>
                Nossa estrutura conta com estações fixas e rotativas por diárias ou planos mensais:
                um de nossos planos irá atender sua necessidade
              </Typography>
              <Container
                disableGutters
                maxWidth={false}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Button
                  variant='contained'
                  color='error'
                  sx={{ fontWeight: 'fontWeightBold' }}
                  onClick={() =>
                    openWhatsappChat(
                      brasilWhatsapp,
                      'Olá! Gostaria de saber mais sobre estações de trabalho'
                    )
                  }
                >
                  Saiba mais
                </Button>
              </Container>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Container
                disableGutters
                maxWidth={false}
                sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}
              >
                <Typography
                  variant='h4'
                  sx={{
                    fontFamily: 'Gabriela Stencil Black',
                    color: '#CBB166',
                  }}
                >
                  Escritório Virtual
                </Typography>
              </Container>
              <Typography fontSize={25} align='center' sx={{ mt: 2, mb: 2 }}>
                Estabeleça sua presença no mercado local. Planos que incluem gestão de
                correspondência, sala de reunião e atendimento de ligações
              </Typography>
              <Container
                disableGutters
                maxWidth={false}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Button
                  variant='contained'
                  color='warning'
                  sx={{ fontWeight: 'fontWeightBold' }}
                  onClick={() =>
                    openWhatsappChat(
                      brasilWhatsapp,
                      'Olá! Gostaria de saber mais sobre escritório virtual'
                    )
                  }
                >
                  Saiba mais
                </Button>
              </Container>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Container
                disableGutters
                maxWidth={false}
                sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}
              >
                <Typography
                  variant='h4'
                  sx={{
                    fontFamily: 'Gabriela Stencil Black',
                    color: '#92A069',
                  }}
                >
                  Salas Privativas
                </Typography>
              </Container>
              <Typography fontSize={25} align='center' sx={{ mt: 2, mb: 2 }}>
                Todos os custos inclusos em um só contrato: água, energia, internet, IPTU, limpeza,
                condomínio, mobília, recepção. Suas despesas reduzidas e economia colaborativa
              </Typography>
              <Container
                disableGutters
                maxWidth={false}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Button
                  variant='contained'
                  color='success'
                  sx={{ fontWeight: 'fontWeightBold' }}
                  onClick={() =>
                    openWhatsappChat(
                      brasilWhatsapp,
                      'Olá! Gostaria de saber mais sobre salas privativas'
                    )
                  }
                >
                  Saiba mais
                </Button>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

export default function Plans() {
  return <PlansContent />;
}
