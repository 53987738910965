import CookieConsent from 'react-cookie-consent';
import { hot } from 'react-hot-loader/root';

import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@mui/material/styles';

import AboutUs from './components/AboutUs';
import Benefits from './components/Benefits/index';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import Map from './components/Map';
import NavBar from './components/NavBar';
import useAppBarHeight from './components/NavBar/utils';
import Plans from './components/Plans/index';
import PrivateRooms from './components/PrivateRooms';
import Testimonials from './components/Testimonials/index';
import theme from './components/Theme';
import VirtualOffice from './components/VirtualOffice';
import Workstations from './components/Workstations';
import { initGTM } from './utils/google-tag-manager';

const handleAcceptCookie = () => {
  const gtmId = process.env.GOOGLE_TAG_MANAGER_ID;
  if (gtmId !== undefined) {
    initGTM(gtmId);
  }
};

const App = function () {
  window?.dataLayer.push({
    event: 'pageview',
  });

  const appBarHeight = useAppBarHeight();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <CookieConsent
        onAccept={handleAcceptCookie}
        enableDeclineButton={false}
        location='bottom'
        buttonText='OK'
        declineButtonText='Não concordo'
        style={{
          width: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: 5,
        }}
        buttonStyle={{
          borderRadius: 2,
          fontWeight: 600,
          backgroundColor: '#2e7d32',
          color: '#fdfdf3',
        }}
        declineButtonStyle={{
          borderRadius: 2,
          fontWeight: 600,
          backgroundColor: '#d0696a',
          color: '#fdfdf3',
        }}
      >
        Este site utiliza cookies para proporcionar uma melhor experiência de navegação{' '}
      </CookieConsent>
      <NavBar />
      <Container
        component='main'
        maxWidth={false}
        disableGutters
        sx={{ overflow: 'hidden', pt: `${appBarHeight}px` }}
      >
        <AboutUs />
        <Benefits />
        <Plans />
        <Workstations />
        <VirtualOffice />
        <PrivateRooms />
        <Testimonials />
        <ContactForm />
        <Map
          title='companyMap'
          link='https://www.google.com/maps/d/embed?mid=1tYLsQW02efLpzLQy3AeuPDHKpwbF0EE&ehbc=2E312F'
        />
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default hot(App);
