import Container from '@mui/material/Container';

type MapProps = {
  title: string;
  link: string;
};

const iframeStyle: React.CSSProperties = {
  border: 0,
  position: 'relative',
  top: '-60px',
  border: 'none',
};

function Map({ title, link }: MapProps) {
  return (
    <Container id='Localização' maxWidth={false} disableGutters sx={{ mt: 15, overflow: 'hidden' }}>
      <iframe
        title={title}
        src={link}
        width='100%'
        height='450'
        loading='lazy'
        referrerPolicy='no-referrer-when-downgrade'
        frameBorder='0'
        style={iframeStyle}
      />
    </Container>
  );
}

export default Map;
