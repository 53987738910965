import axios from 'axios';

const emailApi = axios.create({ baseURL: 'https://email-api-prod.vilamarieta.com' });
const emailOrganization = 'vilamarieta';
const emailProperty = 'institucional-site';

export async function sendContactEmail(contact) {
  const request = {
    organization: emailOrganization,
    property: emailProperty,
    prospectName: contact.name,
    prospectEmail: contact.email,
    prospectPhone: contact.phone,
    subject: 'Contato site',
    text: `Unidade: ${contact.unit} \n\n ${contact.message}`,
  };
  await emailApi.post('/contact-us', request);
}
