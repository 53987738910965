import { createTheme } from '@mui/material/styles';

import gabrielaStencilBlackWoff from '../../assets/fonts/GabrielaStencilBlack.woff';
import nexaBoldWoff from '../../assets/fonts/NexaBold.woff';
import nexaLightWoff from '../../assets/fonts/NexaLight.woff';
import nexaRegularWoff from '../../assets/fonts/NexaRegular.woff';

const theme = createTheme({
  typography: {
    fontFamily: `'Nexa', sans-serif`,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'Nexa';
            font-style: normal;
            font-weight: 300;
            src: 
              local('Nexa'),
              url(${nexaLightWoff}) format('woff');
          }
          @font-face {
            font-family: 'Nexa';
            font-style: normal;
            font-weight: 500;
            src: 
              local('Nexa'),
              url(${nexaRegularWoff}) format('woff');
          }
          @font-face {
            font-family: 'Nexa';
            font-style: normal;
            font-weight: 700;
            src: 
              local('Nexa'),
              url(${nexaBoldWoff}) format('woff');
          }
          @font-face {
            font-family: 'Gabriela Stencil Black';
            src: 
              local('Gabriela Stencil Black'),
              url(${gabrielaStencilBlackWoff}) format('woff');
          }
          `,
    },
  },
  palette: {
    primary: {
      main: '#8ca066',
      contrastText: '#fff',
    },
    secondary: {
      main: '#e0cb56',
    },
    success: {
      main: '#92A069',
    },
    info: {
      main: '#3E3D38',
    },
    warning: {
      main: '#CBB166',
    },
    error: {
      main: '#7B5248',
    },
    background: {
      default: '#fdfdf3',
    },
  },
});

declare module '@mui/material/styles' {
  type Theme = {
    status: {
      danger: string;
    };
  };
  // allow configuration using `createTheme`
  type ThemeOptions = {
    status?: {
      danger?: string;
    };
  };
}

export default theme;
