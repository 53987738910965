import { StarBorderTwoTone } from '@mui/icons-material';
import { CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { yellow } from '@mui/material/colors';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { brasilWhatsapp, openWhatsappChat } from '../../utils/whatsapp';

function WorkstationsContent() {
  return (
    <>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ mt: 20, pt: 8, pb: 6, backgroundColor: '#7B5248' }}
      >
        <Typography
          variant='h2'
          align='center'
          color='background.default'
          gutterBottom
          sx={{
            fontFamily: 'Gabriela Stencil Black',
            fontWeight: 'fontWeightBold',
          }}
        >
          Estações de Trabalho
        </Typography>
        <Typography
          variant='h4'
          align='center'
          color='background.default'
          component='p'
          sx={{ fontFamily: 'Gabriela Stencil Black' }}
        >
          Espaço Compartilhado
        </Typography>
      </Container>
      <Container sx={{ pt: 15 }}>
        <Grid container spacing={5} alignItems='flex-end'>
          <Grid item key='Diária' xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                title='Diária'
                titleTypographyProps={{ align: 'center' }}
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[200]
                      : theme.palette.grey[700],
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'baseline',
                    mb: 2,
                  }}
                >
                  <Typography variant='h4' color='text.primary'>
                    R$ 42
                  </Typography>
                </Box>

                <Typography variant='subtitle1' align='center'>
                  Frequente o Vila quando quiser e use a mesa que estiver disponível, pagando apenas
                  o dia de uso
                </Typography>
                <Container
                  maxWidth={false}
                  disableGutters
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    textAlign: 'center',
                    p: 0,
                  }}
                >
                  <StarBorderTwoTone sx={{ color: yellow[800] }} />
                  <Typography variant='h7' mt={2} mb={2}>
                    A primeira diária é por nossa conta. Agende já!
                  </Typography>
                </Container>
                <CardActions>
                  <Button
                    fullWidth
                    variant='contained'
                    sx={{ backgroundColor: '#d0696a' }}
                    onClick={() =>
                      openWhatsappChat(
                        brasilWhatsapp,
                        'Olá! Gostaria de saber mais sobre o plano diário das estações de trabalho'
                      )
                    }
                  >
                    SAIBA MAIS
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          </Grid>
          <Grid item key='Flex' xs={12} sm={12} md={4}>
            <Card>
              <CardHeader
                title='Meio-período (Flex)'
                titleTypographyProps={{ align: 'center' }}
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[200]
                      : theme.palette.grey[700],
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'baseline',
                    mb: 2,
                  }}
                >
                  <Typography fontSize={12} color='text.secondary'>
                    A partir de&nbsp;&nbsp;
                  </Typography>
                  <Typography component='h3' variant='h4' color='text.primary'>
                    R$ 219
                  </Typography>
                  <Typography variant='h7' color='text.secondary'>
                    /mês
                  </Typography>
                </Box>

                <Typography variant='subtitle1' align='center'>
                  Tenha uma estação de trabalho com liberdade para definir seus horários, com limite
                  de 30h semanais
                </Typography>
                <Box sx={{ height: '5rem' }} />
                <CardActions>
                  <Button
                    fullWidth
                    variant='contained'
                    sx={{ backgroundColor: '#d0696a' }}
                    onClick={() =>
                      openWhatsappChat(
                        brasilWhatsapp,
                        'Olá! Gostaria de saber mais sobre o plano Flex das estações de trabalho'
                      )
                    }
                  >
                    SAIBA MAIS
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          </Grid>
          <Grid item key='Fixo' xs={12} sm={12} md={4}>
            <Card>
              <CardHeader
                title='Período Integral (Fixo)'
                titleTypographyProps={{ align: 'center' }}
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[200]
                      : theme.palette.grey[700],
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'baseline',
                    mb: 2,
                  }}
                >
                  <Typography fontSize={12} color='text.secondary'>
                    A partir de&nbsp;&nbsp;
                  </Typography>
                  <Typography component='h3' variant='h4' color='text.primary'>
                    R$ 329
                  </Typography>
                  <Typography variant='h7' color='text.secondary'>
                    /mês
                  </Typography>
                </Box>

                <Typography variant='subtitle1' align='center'>
                  Precisa trabalhar o dia inteiro? De segunda à sexta, tenha uma estação de trabalho
                  fixa para chamar de sua!
                </Typography>
                <Box sx={{ height: '5rem' }} />
                <CardActions>
                  <Button
                    fullWidth
                    variant='contained'
                    sx={{ backgroundColor: '#d0696a' }}
                    onClick={() =>
                      openWhatsappChat(
                        brasilWhatsapp,
                        'Olá! Gostaria de saber mais sobre o plano Fixo das estações de trabalho'
                      )
                    }
                  >
                    SAIBA MAIS
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default function Workstations() {
  return <WorkstationsContent />;
}
